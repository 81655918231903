import { inject } from '@angular/core';

import { ConsentProvider } from '~/app/domains/consent/services/common/consent-provider';
import { ConsentTapicoProvider } from '~/app/domains/consent/services/tapico/consent-tapico-provider';
import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';
import {
    ProviderConsentType,
} from '~/app/domains/authentication/enums/authentication-provider.enum';

const consentProviderLibrary = {
    [ProviderConsentType.TAPICO]: (
    ): ConsentTapicoProvider => new ConsentTapicoProvider(),
    [ProviderConsentType.NONE]: (
    ): ConsentProvider => undefined as unknown as ConsentProvider,
};

export const ConsentProviderServiceFactory = () => {
    const configurationFacade = inject(ConfigurationFacade);
    const providerName = configurationFacade?.getAuthenticationSnapshot()?.oauthConfiguration?.provider as ProviderConsentType ?? ProviderConsentType.NONE;
    return consentProviderLibrary[providerName]();
};
