import { inject } from '@angular/core';
import {
    interval,
    map,
    Observable,
    of,
    switchMap,
    take,
} from 'rxjs';

import { TapicoConsentParameters } from '~/app/domains/authentication/services/oauth/tapico/oauth-tapico-provider.interface';
import { ConsentProvider } from '~/app/domains/consent/services/common/consent-provider';
import { ConsentProviderFacade } from '~/app/domains/consent/services/common/consent-provider.facade';
import { popupCenter } from '~/app/shared/utils/common-utils';
import { ConsentFlowStates } from '~/app/domains/consent/enums/consent-flow-state.enum';

export class ConsentTapicoProvider extends ConsentProvider {
    private windowRef: Window | undefined;

    private consentProviderFacade: ConsentProviderFacade = inject(ConsentProviderFacade);

    constructor() {
        super();
    }

    askUserConsent(url: string): Observable<boolean> {
        // @ts-ignore
        this.windowRef = popupCenter({
            url, title: 'Consent', popupWidth: 480, popupHeight: 900,
        });
        return of(true);
    }

    cancelUserConsent(): void {
        this.windowRef?.close();
    }

    checkState(
        consentStateFromLocalStorage: string | null,
    ): ConsentFlowStates {
        if (
            consentStateFromLocalStorage
            && consentStateFromLocalStorage?.length > 0
        ) {
            const parsedConsentState = JSON.parse(
                consentStateFromLocalStorage,
            ) as TapicoConsentParameters;
            const consentState = this.handlePlatformResponse(parsedConsentState);
            this.consentProviderFacade.updateIsConsentAccepted(consentState);
            this.consentProviderFacade.updateConsentState(consentState);
            return consentState;
        }
        return 'PENDING' as ConsentFlowStates;
    }

    getConsentUrl(): Observable<string> {
        return this.consentProviderFacade
            .getConsentUrl()
            .pipe(switchMap((response) => of(response.consentUrl)));
    }

    handlePlatformResponse(
        params: TapicoConsentParameters,
    ): ConsentFlowStates {
        let newConsentState: ConsentFlowStates;
        if (params.success && params.success === 'true') {
            newConsentState = ConsentFlowStates.SUCCEED;
        } else if (params.error && params.error === 'access_denied') {
            newConsentState = ConsentFlowStates.ACCESS_DENIED;
        } else {
            newConsentState = ConsentFlowStates.PENDING;
        }
        return newConsentState;
    }

    repeat = <T>(
        intervalDelay: number,
        numberOfRepetition: number,
        action: (params?: any) => T,
    ) => interval(intervalDelay).pipe(
        take(numberOfRepetition),
        map(() => action()),
    );

    updateConsentState(consent: ConsentFlowStates): void {
        this.consentProviderFacade.updateConsentState(consent);
    }

    resetState(): void {
        this.consentProviderFacade.resetTapicoState();
    }
}
