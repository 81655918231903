import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Observable,
} from 'rxjs';

import { ConsentUrlResponse } from '~/app/domains/consent/types/consent-url.response';


@Injectable({
    providedIn: 'root',
})
export class ConsentService {
    constructor(private http: HttpClient) {
    }

    getConsentUrl() : Observable<ConsentUrlResponse> {
        return this.http.get<ConsentUrlResponse>('/members/consents');
    }
}
