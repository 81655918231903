import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConsentService } from '~/app/core/services/api/consent/consent.service';
import { ApplicationFacade } from '~/app/core/state/application/application-facade/application.facade';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { ConsentFlowStates } from '~/app/domains/consent/enums/consent-flow-state.enum';
import { ConsentUrlResponse } from '~/app/domains/consent/types/consent-url.response';

@Injectable({
    providedIn: 'root',
})
export class ConsentProviderFacade {
    constructor(private applicationFacade: ApplicationFacade, private consentService: ConsentService, private authenticationFacade: AuthenticationFacade) {
    }

    getConsentUrl(): Observable<ConsentUrlResponse> {
        return this.consentService.getConsentUrl();
    }

    updateConsentState(consentState: ConsentFlowStates) {
        this.applicationFacade.updateConsentState(consentState);
    }

    resetTapicoState(): void {
        localStorage.removeItem('consentState');
        this.applicationFacade.updateConsentState(ConsentFlowStates.PENDING);
    }

    updateIsConsentAccepted(consentState: ConsentFlowStates) {
        this.authenticationFacade.updateIsConsentAccepted(consentState === ConsentFlowStates.SUCCEED);
    }
}
