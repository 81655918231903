import { Observable } from 'rxjs';

import { ConsentProviderInterface } from '~/app/domains/consent/services/common/consent-provider.interface';
import {
    ProviderConsentType,
} from '~/app/domains/authentication/enums/authentication-provider.enum';
import { ConsentFlowStates } from '~/app/domains/consent/enums/consent-flow-state.enum';
import { ProviderType } from '~/app/domains/authentication/types/provider.type';

export abstract class ConsentProvider implements ConsentProviderInterface {
    private _providerName: ProviderType;

    protected constructor() {
        this._providerName = ProviderConsentType.NONE;
    }

    get providerName(): ProviderType {
        return this._providerName;
    }

    set providerName(value: ProviderType) {
        this._providerName = value;
    }

    abstract askUserConsent(consentUrl: string): Observable<boolean>;

    abstract cancelUserConsent(): void;

    abstract getConsentUrl(): Observable<string>;

    abstract handlePlatformResponse(params: any): ConsentFlowStates;

    abstract updateConsentState(consent: ConsentFlowStates): void;

    abstract resetState(): void;
}
